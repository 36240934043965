import Wrapper from "../Wrapper";
import Hero from "../Hero";
import Product from "../Products";

const Home = () => {
  return (
    <>
      <Hero />
      <Product />
    </>
  );
};

export default Home;
